@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
  @apply scroll-smooth hover:scroll-auto
}
body{
  @apply overflow-x-hidden;
}
.humbergerline-1{
  @apply w-[35px] h-[4px] my-2 block bg-primary rounded-full;
}
.humbergerline-2{
  @apply w-[25px] h-[4px] my-2 block bg-primary rounded-full;
}
.humberger-active > span:nth-child(1){
  @apply rotate-45
}
.humberger-active > span:nth-child(2){
  @apply scale-0
}
.humberger-active > span:nth-child(3){
  @apply -rotate-45
}

.navbar-fixed{
  @apply fixed z-[9999] bg-white bg-opacity-70;
  backdrop-filter: blur(5px);
  box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.2)
}